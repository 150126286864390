import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'lg-button',
  templateUrl: './button.component.html',
})
export class LgButtonComponent {
  @ViewChild('button') button!: ElementRef;

  @Input() type: 'button' | 'submit' = 'button';

  @Input() appearance: 'primary' | 'secondary' | 'tertiary' = 'primary';

  @Input() isDisabled?: boolean;

  @Input() isIconButton?: boolean;

  @Input() icon?: string;

  @Input() isLoading?: boolean;

  onFocus(): void {
    this.button.nativeElement.focus();
  }
}
