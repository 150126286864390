<button
  #button
  [type]="type"
  class="font-apax-regular h-[40px] w-full select-none rounded-[4px] text-sm duration-75 last:transition disabled:opacity-30"
  [ngClass]="{
    'bg-brand-primary-blue-base hover:enabled:bg-brand-primary-blue-hover text-white':
      appearance === 'primary',
    'bg-white border border-brand-primary-blue-base text-brand-primary-blue-base hover:enabled:text-brand-primary-blue-base':
      appearance === 'secondary',

    'w-[40px] bg-transparent text-black': isIconButton,
    'hover:cursor-wait': isLoading,
    'disabled:cursor-not-allowed': !isLoading
  }"
  [disabled]="isDisabled || isLoading"
>
  <ng-container *ngIf="!isIconButton && !isLoading">
    <ng-content></ng-content>
  </ng-container>
  <ng-container *ngIf="isIconButton && icon && !isLoading">
    <lg-icon [name]="icon"></lg-icon>
  </ng-container>
  <ng-container *ngIf="isLoading">
    <div class="flex items-center justify-center">
      <lg-spinner [appearance]="appearance === 'primary' ? 'inverse' : 'default'"></lg-spinner>
    </div>
  </ng-container>
</button>
