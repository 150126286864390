import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LgIconModule } from '@components/icon/icon.module';
import { LgSpinnerModule } from '@components/spinner/spinner.module';

import { LgButtonComponent } from './button.component';

@NgModule({
  imports: [CommonModule, LgIconModule, LgSpinnerModule],
  declarations: [LgButtonComponent],
  exports: [LgButtonComponent],
})
export class LgButtonModule {}
